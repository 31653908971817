<template>
  <div>
    <Section1 :keyWord="'Conseguir dinero rápido en Valencia'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4">Dinero rápido Valencia</h2>
      <p>Conseguir <strong>dinero rápido valencia</strong> es más sencillo de lo que te imaginas. Solo necesitas una conexión a internet, tener un vehículo en propiedad y aportar algunos documentos.</p>
      <p>Gracias al crédito con aval de coche de Ibancar puedes conseguir tu <strong>dinero rápido valencia</strong> en menos de 24 horas. El proceso se completa de manera 100% online y en cuestión de minutos.</p>
      <p>Sigue con nosotros para conocer todos los detalles.</p>

      
      <h3 class="naranja">Conseguir dinero rápido en Valencia</h3>
      <p>Veamos cómo puedes conseguir <strong> dinero rápido valencia</strong> paso a paso. Los requisitos son tener un coche en propiedad y una fuente de ingresos.</p>
      <p>Ahora vas a rellenar el formulario de la web de Ibancar con la cantidad que necesitas y algunos datos básicos del vehículo.</p>
      <p>El sistema te muestra una propuesta de crédito preaprobada, que te llegará también a través de correo electrónico y por WhatsApp.</p>
      <p>Acepta la propuesta y aporta la documentación adicional requerida. Nuestro equipo técnico se encarga de validarla al instante.</p>
      <p>Ibancar realiza la transferencia a tu cuenta corriente con el importe de tu préstamo para que puedas disponer del dinero cuanto antes. ¡Así de sencillo!</p>

      <h3 class="naranja">Préstamo con asnef en valencia</h3>
      <p>Esta es una de las dudas más habituales entre los clientes que se interesan por el crédito con aval de coche de Ibancar.</p>
      <p>Tenemos buenas noticias para ti. No importa que hayas sido incluido en algún fichero de impagados como el de la Asnef. En Ibancar puedes solicitar tu <strong> dinero rápido valencia</strong> igualmente.</p>

      <h3 class="naranja">Cuáles son las condiciones del crédito de dinero urgente en Valencia</h3>
      <p>En el crédito de Ibancar, tu vehículo actúa como aval simbólico.</p>
      <p>A diferencia de otras entidades financieras en Valencia, nosotros nunca vamos a cambiar la titularidad del vehículo, no tendrás que dejarlo en un depósito. Ni siquiera necesitas entregar la documentación original ni una copia de las llaves.</p>
      <p>Mientras disfrutas de tu dinero rápido Valencia podrás conducir tu vehículo sin ningún tipo de restricción. Al contrario. Como cliente de Ibancar, tendrás derecho a un paquete de servicios exclusivos.</p>

      <h3 class="naranja">Conseguir dinero rápido en Valencia</h3>

      <ul>
        <li>Gestor personal para asistirte durante todo el proceso.</li>
        <li>Área personal en la web de Ibancar para gestionar tu préstamo.</li>
        <li>Cancelación sencilla del préstamo.</li>
        <li>Amortización desde el primer pago.</li>
        <li>
            Flexibilidad en los pagos:
            <ul>
                <li>Elección del día de abono de las cuotas</li>
                <li>5 días de cortesía para satisfacer la cuota mensual</li>
                <li>Solicitud de periodos de carencia</li>
                <li>Varios métodos de pago disponibles</li>
            </ul>
       
        </li>
        <li>Revisión técnica anual de mantenimiento gratis.</li>
        <li>Servicio de compra de coche en caso de no poder satisfacer el pago.</li>
      </ul>

      <h3 class="naranja">¿Merece la pena conseguir dinero rápido Valencia a través de Ibancar?</h3>
      <p>Si vives en Valencia y necesitas dinero urgente, la fórmula de Ibancar es la más rápida y segura. Ten en cuenta que todo el proceso se completa a través de internet en unos minutos.</p>
      <p>El dinero llega a tu cuenta en menos de 24 horas, y vas a poder seguir disfrutando de tu vehículo como lo hacías hasta ahora. ¡Solicita ahora tu <strong> dinero rápido Valencia!</strong></p>



 
    </div>


    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero al instante con ASNEF</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Obtén dinero rápido con tu Coche, sin empeñar y sin dejar de conducirlo</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Dinero rápido ▷ Valencia | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Si buscas conseguir dinero rápido en Valencia, te interesa este listado de préstamos.'},
        {name:'keywords', content:'dinero rápido valencia'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>