<template>
  <div>
    <Section1 :keyWord="'La mejor solución para conseguir dinero rápido por tu coche'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4">Dinero rápido con tu coche</h2>

      <h3 class="naranja">Dinero rápido al instante</h3>
      <p class="">Necesitas dinero urgente, vas a tu banco a pedir un préstamo y te dicen que no pueden concedértelo porque apareces en las bases de datos de impagados de la ASNEF. Entonces, ¿qué puedes hacer?  </p>

      <p class="">Realmente necesitas el dinero cuanto antes y no tienes tiempo para realizar todos los trámites necesarios para que te eliminen de los listados de impagados.</p>

      <p class="">No te preocupes. La solución es muy sencilla. Ahora puedes conseguir el dinero al instante, aunque aparezcas en la lista de impagados de ASNEF, mediante el préstamo de <strong>dinero rápido</strong> de Ibancar.</p>

      <p class="">Además de ahorrarte tiempo en reuniones, firmas de documentos y plazos que se eternizan, en Ibancar puedes disponer de tu dinero al instante con ASNEF completando todo el proceso de manera online y en cuestión de minutos. A continuación, te explicamos todos los detalles para solicitar el préstamo de dinero al instante con ASNEF.</p>

      <h3 class="naranja">¿De verdad me concederán el préstamo de dinero rápido aunque aparezca en los ficheros de la ASNEF?</h3>
      <p class="">Por supuesto. Y además no hay ningún secreto ni fórmula mágica. Ibancar es una empresa con la experiencia suficiente para saber que el hecho de aparecer en una lista de impagados no implica que el cliente no pueda devolver el préstamo con total solvencia en los plazos acordados.</p>

      <p class="">Ya son más de 2.000 clientes los que tienen un préstamo con nosotros, y muchos de ellos están o han estado en la lista de ASNEF por diferentes motivos. Cualquier persona puede ser incluida en la ASNEF por múltiples motivos y por una cantidad muy baja. Para Ibancar, este no es motivo suficiente ni justo para denegar un préstamo.</p>

      <p class="">Por lo tanto, si necesitas <strong>dinero rápido</strong> al instante, no te compliques y acude a Ibancar. Nuestro equipo de asesores está disponible en todo momento para ayudarte a conseguir el préstamo que necesitas, sin complicaciones y en solo cuestión de minutos.</p>
    
    <h3 class="naranja">¿Cómo puedo conseguir el préstamo de dinero rápido con ASNEF a través de Ibancar?</h3>
      <p class="">Pues, es muy sencillo. El proceso se realiza de manera online y en un espacio de tiempo muy breve. El modelo de préstamo de Ibancar se basa en el sistema de préstamo con aval de coche. Pero no te asustes.</p>

   <p class=""> Ibancar no funciona como otras empresas de préstamos. Tu coche es un aval testimonial. Vas a poder seguir disfrutándolo con las mismas condiciones que hasta ahora y sin ningún tipo de restricción.</p>
   <p class="">  En otras empresas, para que te puedan conceder el préstamo, lo más probable es que debas dejar tu vehículo en un depósito hasta que se satisfaga la deuda. Sin embargo, con el crédito de <strong>dinero rápido</strong> con ASNEF de Ibancar no es así. </p>
   <p class="">  Al solicitar el préstamo en Ibancar vas a poder seguir conduciendo tu vehículo como siempre. Lo único que no vas a poder es venderlo hasta que no termines de pagar el préstamo, ya que ese es el aval que respalda tu solicitud.</p>
   <p class="">  Solicitar dinero al instante con ASNEF nunca había sido tan fácil y sencillo como lo es ahora. Si necesitas liquidez urgente, no te compliques y acude a Ibancar. Soluciones económicas y rápidas, sin trámites engorrosos, y sin cambios de titularidad del coche.  Tampoco hace falta que entregues copias de las llaves ni documentos originales del vehículo.</p>

<h3 class="naranja">Beneficios del crédito de dinero rápido con ASNEF de Ibancar</h3>
 <p class="">Al acceder al préstamo de dinero al instante con ASNEF de Ibancar te conviertes en un cliente con derecho a un paquete exclusivo de servicios que incluye:</p>
 <ul>
 <li>Garantías de amortización por siniestro.</li>
 <li>Revisión técnica anual de mantenimiento.</li>
 <li>Servicio personalizado de obtención de citas para la ITV.</li>
 <li>Área personal de cliente online para consultar todos los detalles y actualizaciones del préstamo.</li>
 <li>Gestor personal para acompañarte durante el proceso y resolver cualquier duda que pueda surgirte.</li>
 </ul>
 <p class="">En definitiva, con Ibancar tienes la seguridad de contar con un crédito fácil y rápido al instante sin preocuparte por perder el coche que es tu aval, y además obtienes una serie de servicios añadidos muy útiles.</p>
<h3 class="naranja">¿Cual es el proceso para solicitar un préstamo de dinero rápido con ASNEF?</h3>
<p class="">Para solicitar tu préstamo de dinero al instante con ASNEF es muy fácil. Solo tienes que rellenar un sencillo formulario con tus datos personales y la cantidad que quieres solicitar. Una vez enviada toda la información, el equipo de expertos de Ibancar evaluará tu caso y recibirás una oferta personalizada, y en menos de 24 horas tendrás el dinero disponible en tu cuenta.</p>
<p class="">Cómo ves, el proceso con Ibancar es rápido, sencillo y seguro, para que puedas disponer del dinero al instante y sin complicaciones. </p>

<h3 class="naranja">¿Cuáles son los documentos que tengo que enviar a Ibancar para solicitar el préstamo de dinero rápido con ASNEF?</h3>
<p class="">Para solicitar el préstamo de <strong>dinero rápido</strong> con ASNEF deberás enviar algunos documentos básicos para comprobar que cumples los requisitos establecidos. Pero no te preocupes, estos documentos están al alcance de tu mano y no tienes que pedirle ayuda a ningún amigo o familiar.</p>
<p class="">Los documentos que deberás enviar son:</p>
<ul>
<li>Escáner de tu DNI, ambas caras en fondo blanco.</li>
<li>Última nómina, pensión o autónomo.</li>
<li>Foto Selfie con DNI.</li>
</ul>
<p class="">Además de estos documentos básicos, deberás aportar la siguiente documentación del vehículo:</p>
<ul>
<li>Permiso de circulación y ficha técnica.</li>
<li>1 Foto del cuentakilómetros con tu DNI.</li>
<li>4 fotos del coche</li>
</ul>
<p class="">Toda esta información la podrás encontrar detalladamente abajo en el apartado de preguntas comunes. También encontrarás información acerca de las condiciones en las que debe estar el vehículo para poder solicitar el préstamo de dinero al instante con ASNEF.</p>

<h3 class="naranja">¿El préstamo de dinero rápido con ASNEF está disponible en toda España?</h3>
<p class="">Por supuesto. Todos los créditos que ofrece Ibancar están disponibles en cualquier lugar de la península y las Islas Baleares. Solo tienes que rellenar el formulario y en menos de 24 horas tendrás el dinero disponible en tu cuenta. Y lo mejor, sin papeleos y sin tener que desplazarte a ningún sitio.</p>
<p class="">Así que si estás buscando un préstamo de <strong>dinero rápido</strong> al instante con ASNEF, no dudes en acudir a Ibancar. Conseguirás el dinero que necesitas en cuestión de minutos.</p>

    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero al instante con ASNEF</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Obtén dinero rápido con tu Coche, sin empeñar y sin dejar de conducirlo</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../dinero-rapido-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Obtén dinero rápido con tu Coche, sin empeñar y sin dejar de conducirlo</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Dinero rápido, en menos de 24 horas con tu coche',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Necesitas conseguir dinero rápido con tu coche? Entra aquí, te explicamos con lujos y detalles todo lo que necesitas saber para adquirir una financiación.'},
        {name:'keywords', content:'Dinero rápido con tu coche, Préstamo rápido y fácil, Conseguir dinero fácil.'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>